import { render, staticRenderFns } from "./Profile.vue?vue&type=template&id=1bf7de41&scoped=true"
import script from "./Profile.vue?vue&type=script&setup=true&lang=ts"
export * from "./Profile.vue?vue&type=script&setup=true&lang=ts"
import style0 from "./Profile.vue?vue&type=style&index=0&id=1bf7de41&prod&scoped=true&lang=scss"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "1bf7de41",
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {ComposedImageBlockiesAvatar: require('/root/build_gs/core/components/composed/image/BlockiesAvatar.vue').default,ComposedActionCopyToClipboard: require('/root/build_gs/core/components/composed/action/CopyToClipboard.vue').default,BaseImageSvgThemedIcon: require('/root/build_gs/core/components/base/image/SvgThemedIcon.vue').default,BaseCardSideSplit: require('/root/build_gs/core/components/base/card/SideSplit.vue').default})
