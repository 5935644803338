import { render, staticRenderFns } from "./TwoFASwitcher.vue?vue&type=template&id=7f97cee5&scoped=true"
import script from "./TwoFASwitcher.vue?vue&type=script&setup=true&lang=ts"
export * from "./TwoFASwitcher.vue?vue&type=script&setup=true&lang=ts"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "7f97cee5",
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {BaseSwitchOnly: require('/root/build_gs/core/components/base/switch/Only.vue').default,ComposedMenuItemCollapsible: require('/root/build_gs/core/components/composed/menu-item/Collapsible.vue').default})
