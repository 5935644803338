import { render, staticRenderFns } from "./AuthCore.vue?vue&type=template&id=0374f57e&scoped=true"
import script from "./AuthCore.vue?vue&type=script&lang=ts&setup=true"
export * from "./AuthCore.vue?vue&type=script&lang=ts&setup=true"
import style0 from "./AuthCore.vue?vue&type=style&index=0&id=0374f57e&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "0374f57e",
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {FlowsSigninStepsConnectMyWallet: require('/root/build_gs/core/components/flows/signin/steps/ConnectMyWallet.vue').default,FlowsSigninStepsSignInWithEmail: require('/root/build_gs/core/components/flows/signin/steps/SignInWithEmail.vue').default,FlowsSigninStepsTwoFactorSendCode: require('/root/build_gs/core/components/flows/signin/steps/TwoFactorSendCode.vue').default,FlowsSigninStepsForgotPassword: require('/root/build_gs/core/components/flows/signin/steps/ForgotPassword.vue').default,FlowsSigninStepsSignUpWithEmail: require('/root/build_gs/core/components/flows/signin/steps/SignUpWithEmail.vue').default})
