import { render, staticRenderFns } from "./FullScreenLoading.vue?vue&type=template&id=8a05cc32&scoped=true"
import script from "./FullScreenLoading.vue?vue&type=script&lang=ts&setup=true"
export * from "./FullScreenLoading.vue?vue&type=script&lang=ts&setup=true"
import style0 from "./FullScreenLoading.vue?vue&type=style&index=0&id=8a05cc32&prod&scoped=true&lang=scss"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "8a05cc32",
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {BaseLoaderAffility: require('/root/build_gs/core/components/base/loader/Affility.vue').default})
