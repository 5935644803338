import { render, staticRenderFns } from "./ForgotPassword.vue?vue&type=template&id=f25e9c40&scoped=true"
import script from "./ForgotPassword.vue?vue&type=script&lang=ts&setup=true"
export * from "./ForgotPassword.vue?vue&type=script&lang=ts&setup=true"
import style0 from "./ForgotPassword.vue?vue&type=style&index=0&id=f25e9c40&prod&scoped=true&lang=scss"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "f25e9c40",
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {BaseImageSvgThemedIcon: require('/root/build_gs/core/components/base/image/SvgThemedIcon.vue').default,ComposedFormItemInput: require('/root/build_gs/core/components/composed/form-item/Input.vue').default,BaseButtonSimple: require('/root/build_gs/core/components/base/button/Simple.vue').default})
